import { format } from 'date-fns';
import React from 'react';
import { Input } from 'reactstrap';

const getGender = (val, options) => {
    return options.find(opt => opt.genderCode === val)?.genderDesc;
};

const getRace = (val, options) => {
    return options.find(opt => opt.raceCode === val)?.raceDesc;
};

const checkMatch = (data, fieldName) => {
    if (data[fieldName] == 255) {
        return 'match';
    } else if (data[fieldName] == 1) {
        return 'mismatch';
    } else if (data[fieldName] != 0) {
        return 'mayMatch';
    }
    return 'noClassMatch';
};

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

export const ReviewHeader = ({ duplicate }) => {
    return (
        <thead className="thead-light">
            <tr>
                <th>
                    <h5 className="text-strong m-0 text-center">ID</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">
                        Last Four SSN
                    </h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">First Name</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">Middle Name</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">Last Name</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">
                        Alt. Last Name
                    </h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">DOB</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">Gender</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">Race</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">HS CEEB Code</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">HS Name</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">HS City</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">HS Grad Yr</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">Score</h5>
                </th>
                <th>
                    <h5 className="text-strong mb-3 mb-md-0 text-center">
                        {duplicate ? 'Dup. Group Num.' : 'Select One'}
                    </h5>
                </th>
            </tr>
        </thead>
    );
};

export const ReviewRow = props => {
    const { data, races, genders } = props;
    console.log(data);
    return (
        <tr style={{ borderBottom: '6px solid #C0C0C0' }}>
            <td>{data.id}</td>
            <td>{data.last4SSN}</td>
            <td>{data.firstName}</td>
            <td>{data.middleName}</td>
            <td>{data.lastName}</td>
            <td></td>
            <td>{data.dob ? format(new Date(data.dob), 'MM/dd/yyyy') : ''}</td>
            <td>{getGender(data.gender, genders)}</td>
            <td>{getRace(data.race, races)}</td>
            <td>{data.highSchoolCeebcode}</td>
            <td>{data.hsName}</td>
            <td>{data.hsCity}</td>
            <td>{data.highSchoolGraduationYear}</td>
            <td></td>
            <td className="text-center"></td>
        </tr>
    );
};

export const DuplicateRow = props => {
    const { data, races, genders, setDuplicate } = props;
    return (
        <tr>
            <td>{data.id}</td>
            <td>{data.last4SSN}</td>
            <td>{data.firstName}</td>
            <td>{data.middleName}</td>
            <td>{data.lastName}</td>
            <td></td>
            <td>{data.dob ? format(new Date(data.dob), 'MM/dd/yyyy') : ''}</td>
            <td>{getGender(data.gender, genders)}</td>
            <td>{getRace(data.race, races)}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td className="text-center">
                <input
                    type="number"
                    value={data.dupGroupNum || ''}
                    onChange={e => setDuplicate(e.target.value, data)}
                    style={{ width: 70 }}
                />
            </td>
        </tr>
    );
};

export const EditRow = props => {
    const { data, races, genders, match, setActive, active } = props;
    return (
        <tr>
            <td>{data.id}</td>
            <td className={checkMatch(data, 'last4SSNCo')}>{data.last4SSN}</td>
            <td className={checkMatch(data, 'firstNameCo')}>{data.firstName}</td>
            <td className={checkMatch(data, 'middleNameCo')}>{data.middleName}</td>
            <td className={checkMatch(data, 'lastNameCo')}>{data.lastName}</td>
            <td>{data.altLastName}</td>
            <td className={checkMatch(data, 'dateOfBirthCo')}>
                {data.dateOfBirth ? format(new Date(data.dateOfBirth), 'MM/dd/yyyy') : ''}
            </td>
            <td className={checkMatch(data, 'genderCo')}>
                {getGender(data.gender, genders)}
            </td>
            <td className={checkMatch(data, 'raceCo')}>{getRace(data.race, races)}</td>
            <td className={checkMatch(data, 'highSchoolCeebcodeCo')}>
                {data.hsceebCode}
            </td>
            <td>{data.hsName}</td>
            <td>{data.hsCity}</td>
            <td>{data.hsGradYear}</td>
            <td>
                <h5 className="mb-0 text-strong">{data.score.toFixed(2)}</h5>
            </td>
            <td
                className="text-center"
                onClick={() => setActive(data.id)}
                style={{ cursor: 'pointer' }}
            >
                <input type="radio" checked={active} />
            </td>
        </tr>
    );
};

export const NoMatchRow = props => {
    const { setActive, active } = props;
    return (
        <tr>
            <td colSpan="14" className="text-right">
                <h5 className="my-2 text-strong">No Match (Non-Link)</h5>
            </td>
            <td
                className="text-center"
                onClick={() => {
                    setActive('-1');
                }}
            >
                <input type="radio" checked={active} />
            </td>
        </tr>
    );
};
