import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { TooltipWrapper } from 'components/blocks';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });

const link = (cell, row, rowIndex, formatExtraData) => {
    return (
        <TooltipWrapper
            id={`last-${row.linkRequestID}`}
            text="Go To Review"
            placement="top"
        >
            <div
                className="btn btn-block btn-white text-primary btn-sm border-primary"
                onClick={() => {
                    formatExtraData.onLink({ row, duplicate: formatExtraData.duplicate });
                }}
            >
                <FontAwesomeIcon className="fa-w-20" icon={faFile} /> {cell}
            </div>
        </TooltipWrapper>
    );
};

const date = (cell, row) => {
    const date = new Date(row.dateRequestRan);
    return format(date, 'MM/dd/yyyy');
};

const number = cell => {
    return cell.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

export const Columns = props => [
    {
        dataField: 'linkRequestID',
        text: 'ID',
        sort: true,
        headerStyle: getWidthStyle(55),
    },
    {
        dataField: 'dataSetSource',
        text: 'Data Set Source',
        sort: true,
        headerStyle: { minWidth: 225 },
    },
    {
        dataField: 'campus',
        text: 'Data Source Name',
        sort: true,
        headerStyle: { minWidth: 225 },
    },
    {
        dataField: 'dateRequestRan',
        text: 'Data Request Ran',
        sort: true,
        formatter: date,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'totalRecordN',
        text: 'Total Records',
        sort: true,
        formatter: number,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'autoLinkN',
        text: 'Auto Link',
        sort: true,
        formatter: number,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'autoNonLinkN',
        text: 'Auto Non-Link',
        sort: true,
        formatter: number,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'possibleLinkN',
        text: 'Possible Link',
        sort: true,
        formatter: number,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'plNeedReviewN',
        text: 'PL Need Review',
        classes: 'text-center',
        sort: true,
        formatter: link,
        headerStyle: getWidthStyle(100),
        formatExtraData: { ...props, duplicate: false },
    },
    {
        dataField: 'nonLinkDupN',
        text: 'Non-Link Dup',
        classes: 'text-center',
        sort: true,
        formatter: link,
        headerStyle: getWidthStyle(100),
        formatExtraData: { ...props, duplicate: true },
    },
    {
        dataField: 'nldUnassignedN',
        text: 'NLD Unassigned',
        sort: true,
        headerStyle: getWidthStyle(100),
    },
];
