import React, { Fragment } from 'react';
import { Button, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import {
    faCheck,
    faDownload,
    faExclamationCircle,
    faInfoCircle,
    faPlay,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { dataSubmissionService } from 'services';
import { downloadCsv, downloadExcel } from 'helpers';

import { TooltipWrapper } from '../../blocks';

const getWidthStyle = width => ({ width, minWidth: width, maxWidth: width });
const canApprove = val => ['Pending Approval', 'Warnings Exist'].includes(val);

const dateFormatter = (cell, row) => {
    const date = new Date(row.transmissionDate);
    return (
        <Fragment>
            {format(date, 'M/d/yyyy')}
            <span className="d-block small">{format(date, 'h:mm aa')}</span>
        </Fragment>
    );
};

const errorsFormatter = (cell, row) => {
    const getErrors = async () => {
        const fileName = row.fileType + row.reportingYear + row.campusCHEId;
        if (row.submissionID) {
            const res = await dataSubmissionService.submissionErrors(row.submissionID);
            if(res) {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}_errors.xlsx`;
                link.click();
            }
            //if (res) downloadCsv(res, fileName + "_errors");
        } else {
            const res = await dataSubmissionService.transmissionErrors(
                row.transmissionID
            );
            if(res) {
                const type = res.headers['content-type'];
                const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${fileName}_errors.xlsx`;
                link.click();
            }
            //if (res) downloadCsv(res, fileName + "_errors");
        }
    };
    if (row.errors)
        return (
            <div className="has-error">
                <Badge color="danger">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />{' '}
                    {row.errors}
                </Badge>
                <Button color="white" className="btn-sm mt-2" onClick={getErrors}>
                    <FontAwesomeIcon icon={faDownload} /> Download
                </Button>
            </div>
        );
    return 0;
};

const warningsFormatter = (cell, row) => {
    const fileName = row.fileType + row.reportingYear + row.campusCHEId;
    const getWarnings = async () => {
        const res = await dataSubmissionService.submissionWarnings(row.submissionID);
        if(res) {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}_warnings.xlsx`;
            link.click();
        }
        //if (res) downloadCsv(res, fileName + '_warnings');
    };
    if (row.warnings)
        return (
            <div className="has-warning">
                <Badge color="warning">
                    <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />{' '}
                    {row.warnings}
                </Badge>
                <Button color="white" className="btn-sm mt-2" onClick={getWarnings}>
                    <FontAwesomeIcon icon={faDownload} /> Download
                </Button>
            </div>
        );
    return 0;
};

const statusTransmissionFormatter = (cell, row) => {
    return (
        <div className="d-flex align-items-center">
            <div className="mr-1">{row.status}</div>
            {row.statusExplanation != 'null' && row.statusExplanation && (
                <TooltipWrapper
                    id={`statusExplanation-${row.transmissionID}`}
                    text={`${row.statusExplanation}`}
                    placement="right"
                >
                    <div className="text-primary">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                </TooltipWrapper>
            )}
        </div>
    );
};

const lastFormatter = (cell, row) => {
    const date = new Date(row.lastStatusUpdate);
    if (row.lastStatusUpdate == null) {
        return (
            <Fragment>
                N/A
                <TooltipWrapper
                    id={`last-${row.submissionID}`}
                    text={`Submission Id: ${row.submissionID}`}
                    placement="right"
                >
                    <div className="text-primary">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                </TooltipWrapper>
            </Fragment>
        );
    }
    return (
        <Fragment>
            {format(date, 'M/d/yyyy')}
            <span className="d-block small">{format(date, 'h:mm aa')}</span>
            <TooltipWrapper
                id={`last-${row.submissionID}`}
                text={`Submission Id: ${row.submissionID}`}
                placement="right"
            >
                <div className="text-primary">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>
            </TooltipWrapper>
        </Fragment>
    );
    /*
    const date = new Date(row.lastStatusUpdate);
    return (
        <div className="d-flex">
            <div className="mr-1">{row.submissionID} </div>
            <TooltipWrapper
                id={`last-${row.submissionID}`}
                text={`${format(date, 'MM/dd/yyyy')} at ${format(date, 'h:mm aa')}`}
                placement="right"
            >
                <div className="text-primary">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>
            </TooltipWrapper>
        </div>
    );*/
};

const statusFormatter = (cell, row) => {
    if (row.status === 'Submission Approved') {
        const date = new Date(row.approvalDate);
        return (
            <div className="d-flex align-items-center">
                <FontAwesomeIcon icon={faCheck} className="text-success mr-1" />
                <div className="mr-1">Approved</div>
                <TooltipWrapper
                    id={`status-${row.submissionID}`}
                    text={`By: ${row.approvedBy} on ${format(date, 'M/d/yyyy')}`}
                    placement="left"
                >
                    <div className="text-primary">
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </div>
                </TooltipWrapper>
            </div>
        );
    }
    return row.status;
};

const reportingYearFormatter = (cell, row) => {
    return (
        <Fragment>
            {row.reportingYear - 1}-{Number(row.reportingYear)}
        </Fragment>
    );
};

const fileFormatter = (cell, row) => {
    const fileName = row.fileType + row.reportingYear + row.campusCHEId;
    const download = async () => {
        const res = await dataSubmissionService.downloadFile(row.transmissionID);
        if (res) downloadCsv(res, fileName);
    };
    return (
        <div className="table-actions">
            <TooltipWrapper
                id={`download-${row.transmissionID}`}
                text="Download File"
                placement="top"
                className="action-circle"
            >
                <div onClick={download}>
                    <FontAwesomeIcon icon={faDownload} />
                </div>
            </TooltipWrapper>
        </div>
    );
};

const actionFormatter = (cell, row, i, data) => {
    const approve = async () => {
        const res = await dataSubmissionService.approve(row.submissionID);
        data.onUpdate(res);
    };
    const unapprove = async () => {
        const res = await dataSubmissionService.unapprove(row.submissionID);
        data.onUpdate(res);
    };
    const rerun = async () => {
        const res = await dataSubmissionService.rerun(row.submissionID);
        data.onUpdate(res);
    };
    const download = async () => {
        console.log(row);
        const res = await dataSubmissionService.downloadFile(
            row.associatedTransmissionId
        );
        const fileName = row.fileType + row.reportingYear + row.campusCHEId;
        if (res) downloadCsv(res, fileName);
    };
    if (row.dataMoved) {
        return (
            <div className="table-actions">
                <TooltipWrapper
                    id={`download-${row.submissionID}`}
                    text="Download File"
                    placement="top"
                    className="action-circle"
                >
                    <div onClick={download}>
                        <FontAwesomeIcon icon={faDownload} />
                    </div>
                </TooltipWrapper>
            </div>
        );
    }
    const canBeApproved = canApprove(row.status);
    return (
        <div className="table-actions">
            {row.status === 'Submission Approved' && data.approvePermission && (
                <TooltipWrapper
                    id={`approve-${row.submissionID}`}
                    text="Unapprove"
                    placement="top"
                    className="action-circle"
                >
                    <div onClick={unapprove}>
                        <FontAwesomeIcon icon={faTimes} className="text-danger" />
                    </div>
                </TooltipWrapper>
            )}
            {canBeApproved && data.approvePermission && (
                <TooltipWrapper
                    id={`approve-${row.submissionID}`}
                    text="Approve"
                    placement="top"
                    className="action-circle"
                >
                    <div onClick={approve}>
                        <FontAwesomeIcon icon={faCheck} className="text-success" />
                    </div>
                </TooltipWrapper>
            )}
            <TooltipWrapper
                id={`download-${row.submissionID}`}
                text="Download File"
                placement="top"
                className="action-circle"
            >
                <div onClick={download}>
                    <FontAwesomeIcon icon={faDownload} />
                </div>
            </TooltipWrapper>
            <TooltipWrapper
                id={`rerun-${row.submissionID}`}
                text="Rerun"
                placement="top"
                className="action-circle"
            >
                <div onClick={rerun}>
                    <FontAwesomeIcon icon={faPlay} />
                </div>
            </TooltipWrapper>
        </div>
    );
};

export const ErrorColumns = [
    {
        dataField: 'transmissionID',
        text: 'ID',
        sort: true,
        classes: 'nowrap',
        headerStyle: getWidthStyle(60),
    },
    {
        dataField: 'transmissionDate',
        text: 'Date',
        sort: true,
        formatter: dateFormatter,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'transBy',
        text: 'Trans. By',
        sort: true,
        classes: 'small',
        headerStyle: getWidthStyle(160),
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: statusTransmissionFormatter,
        sort: true,
        headerStyle: getWidthStyle(120),
    },
    {
        dataField: 'fileType',
        text: 'File type',
        sort: true,
        headerStyle: getWidthStyle(80),
    },
    {
        dataField: 'reportingYear',
        text: 'Reporting Year',
        sort: true,
        classes: 'nowrap',
        formatter: reportingYearFormatter,
        headerStyle: getWidthStyle(150),
    },
    {
        dataField: 'campus',
        text: 'Campus',
        sort: true,
        style: { minWidth: 300 },
    },
    {
        dataField: 'errors',
        text: 'Errors',
        sort: true,
        classes: 'text-center',
        formatter: errorsFormatter,
        headerStyle: getWidthStyle(100),
    },
    {
        dataField: 'fileUploaded',
        text: 'Download',
        formatter: fileFormatter,
        headerStyle: getWidthStyle(80),
    },
];

export const SubmissionColumns = props => {
    return [
        {
            dataField: 'campusName',
            text: 'Campus',
            sort: true,
            headerStyle: getWidthStyle(300),
        },
        {
            dataField: 'reportingYear',
            text: 'Reporting Year',
            sort: true,
            classes: 'nowrap',
            formatter: reportingYearFormatter,
            headerStyle: getWidthStyle(150),
        },
        {
            dataField: 'fileType',
            text: 'File type',
            sort: true,
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'lastStatusUpdate',
            text: 'Last Update',
            sort: true,
            formatter: lastFormatter,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'records',
            text: 'Records',
            sort: true,
            classes: 'text-center',
            headerStyle: getWidthStyle(80),
        },
        {
            dataField: 'errors',
            text: 'Errors',
            sort: true,
            classes: 'text-center',
            formatter: errorsFormatter,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'warnings',
            text: 'Warnings',
            sort: true,
            classes: 'text-center',
            formatter: warningsFormatter,
            headerStyle: getWidthStyle(100),
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            classes: 'nowrap',
            formatter: statusFormatter,
            headerStyle: getWidthStyle(110),
        },
        {
            dataField: 'actions',
            formatter: actionFormatter,
            formatExtraData: props,
            headerStyle: getWidthStyle(130),
        },
    ];
};
