import { Input, Button } from 'reactstrap';
import React, { useEffect, useState, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { academicProgramsService } from 'services';
import { UrlEnum } from 'shared';
import { AcademicAffairViewAuditModal } from './academic-affair-view-audit-modal';
import Spinner from 'components/blocks/Spinner';

const Header = props => {
    return (
        <th colSpan={3}>
            <div className="d-flex justify-content-center">
                <h5 className="mb-0 text-strong">{props.children}</h5>
            </div>
        </th>
    );
};

const SubHeader = () => {
    return (
        <thead>
            <tr>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Head Count
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    FTE
                </th>
                <th className="text-center text-strong" style={{ minWidth: 85 }}>
                    Degrees
                </th>
            </tr>
        </thead>
    );
};

const Cell = props => {
    return <td className="text-center text-strong">{props.children}</td>;
};

export const AcademicAffairProgramView = ({ academicAffairsData }) => {
    const [cips, setCips] = useState([]);
    const [extraDetailsLoading, setExtraDetailsLoading] = useState(false);
    const [extraDetailsHasLoaded, setExtraDetailsHasLoaded] = useState(false);
    const INITIAL_STATE = {
        fafsaYear1: '',
        fafsaYear2: '',
        fafsaYear3: '',
        year1Headcount: '',
        year1FTE: '',
        year1Degrees: '',
        year2Headcount: '',
        year2FTE: '',
        year2Degrees: '',
        year3Headcount: '',
        year3FTE: '',
        year3Degrees: '',
    };
    const [extraViewDetails, setExtraViewDetails] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        INITIAL_STATE
    );

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const res = await academicProgramsService.getSixCips();
                setCips(res);
            } catch (error) {}
        };
        fetchOptions();
    }, []);

    useEffect(() => {
        const fetchExtraViewDetails = async () => {
            try {
                const details = await academicProgramsService.getProgramExtraViewDetails(
                    programId
                );
                console.log(details);
                if (details != null) {
                    setExtraDetailsLoading(false);
                    setExtraDetailsHasLoaded(true);
                } else {
                    setExtraDetailsHasLoaded(false);
                    setExtraDetailsLoading(false);
                }
                setExtraViewDetails(details);
            } catch (error) {}
        };
        fetchExtraViewDetails();
    }, [academicAffairsData]);

    const getExtraViewDetails = async () => {
        if (academicAffairsData == []) return;
        console.log(academicAffairsData);
        const details = await academicProgramsService.getProgramExtraViewDetails(
            academicAffairsData.programDetails.programId
        );
        console.log(details);
        if (details != null) {
            setExtraDetailsLoading(false);
            setExtraDetailsHasLoaded(true);
        } else {
            setExtraDetailsHasLoaded(false);
            setExtraDetailsLoading(false);
        }
        setExtraViewDetails(details);
    };

    const makeShortDate = date => {
        return new Date(date).toLocaleDateString('en-US');
    };

    const [isAuditModalOpen, setIsAuditOpen] = useState(false);
    const [auditId, setAuditId] = useState(0);

    const statusSelectOptions = [
        { label: 'New', value: 0 },
        { label: 'Active', value: 1 },
        { label: 'Suspended', value: 3 },
        { label: 'Eliminated', value: 4 },
        { label: 'Merged', value: 5 },
    ];

    const getSelectOption = val => {
        return statusSelectOptions.find(x => x.value === val);
    };

    const toggleAudit = () => setIsAuditOpen(!isAuditModalOpen);

    const getCipLabel = val => {
        return cips.find(cip => cip.cipCode === val)?.cipName || '---';
    };

    if (!academicAffairsData.programDetails) {
        return null;
    }

    const TurnKeyToPlainText = key => {
        switch (key) {
            case 'cipSixDigitCode':
                return `CIP Six Digit Code`;
            case 'degreeLevelCode':
                return `Degree Level Code`;
            case 'programFormalName':
                return `Program Name`;
            case 'programStartYear':
                return `Start Year`;
            case 'programEndYear':
                return `End Year`;
            case 'programStatusId':
                return `Status ID`;
            case 'programApprovalDate':
                return `Approval Date`;
            case 'programDistanceEdApprovedFlag':
                return `Distance Ed Approved Flag`;
            case 'programPffStemFlag':
                return `PFF STEM Flag`;
            case 'programCreditReqMin':
                return `Credit Requirement Minimum`;
            case 'programCreditReqMax':
                return `Credit Requirement Maximum`;
            case 'workforceReadyGrantFlag':
                return `Workforce Ready Grant Flag`;
            case 'programSuspendYear':
                return `Suspend Year`;
            case 'programDistanceEdId':
                return `Distance Ed ID`;
            case 'programDistanceEdOnlineId':
                return `Distance Ed Online ID`;
            default:
                return '';
        }
    };

    const GetAuditFieldsList = audit => {
        const retList = [];
        for (const key in audit) {
            if (
                audit[key] != null &&
                key != 'programAuditId' &&
                    key != 'requesterName' &&
                    key != 'programId' &&
                    key != 'programApprovalDate' &&
                    key != 'lastUpdateDate'
            ) {
                retList.push(TurnKeyToPlainText(key));
            }
        }
        return retList.join(', ');
    };

    const renderAuditRows = () => {
        let rows = [];
        if (audits == null) return rows;

        audits.map(audit => {
            rows.push(
                <tr key={audit.programAuditId}>
                    <td>{audit.lastUpdateDate.substring(0, 10)}</td>
                    <td>{audit.requesterName}</td>
                    <td>{GetAuditFieldsList(audit)}</td>
                    <td>
                        <Button
                            className="action-circle"
                            onClick={() => {
                                // open modal
                                setAuditId(audit.programAuditId);
                                toggleAudit(true);
                            }}
                        >
                            <FontAwesomeIcon
                                id={audit.programAuditId + '-edit'}
                                icon={faSearchPlus}
                            />
                        </Button>
                    </td>
                </tr>
            );
        });

        return rows;
    };
    const {
        degreeLevelDesc,
        programDetails,
        credentials,
        locations,
        audits,
    } = academicAffairsData;
    const {
        programFormalName,
        cipSixDigitCode,
        programStatusId,
        programEndYear,
        programCreditReqMin,
        programCreditReqMax,
        programApprovalDate,
        programDistanceEd,
        programDistanceEdOnline,
        programComments,
        programPffStemFlag,
        workforceReadyGrantFlag,
        programId,
        careerRelevanceFlag,
    } = programDetails;

    const { locationName } = academicAffairsData.programInstitution;
    const {
        fafsaYear1,
        fafsaYear2,
        fafsaYear3,
        year1Headcount,
        year1FTE,
        year1Degrees,
        year2Headcount,
        year2FTE,
        year2Degrees,
        year3Headcount,
        year3FTE,
        year3Degrees,
    } = extraViewDetails;

    const renderCredentialRows = () => {
        let rows = [];
        if (credentials == null) return rows;

        credentials.map(credential => {
            rows.push(
                <tr key={credential.credentialId}>
                    <td>{credential.credentialId}</td>
                    <td>{credential.programURL || 'N/A'}</td>
                    <td>{credential.programDescription || 'N/A'}</td>
                    <td>{credential.keywords || 'N/A'}</td>
                    <td>{credential.ctid || 'N/A'}</td>
                </tr>
            );
        });

        return rows;
    };

    return (
        <React.Fragment>
            <div className="page-container">
                <div className="container-fluid">
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <a href={UrlEnum.SEARCH_ACADEMIC_AFFAIRS_PROGRAMS}>
                            <FontAwesomeIcon icon={faArrowLeft} /> back to search
                        </a>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Program Name</label>
                                        <div>{programFormalName}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Program Id</label>
                                        <div>{programId}</div>
                                    </div>
                                    <div className="col-md">
                                        <label>Institution</label>
                                        <div>{locationName}</div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Degree/Certificate Level </label>
                                        <div>{degreeLevelDesc}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>CIP</label>
                                        <div>{cipSixDigitCode}</div>
                                        <div>{getCipLabel(cipSixDigitCode)}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Career Relevance</label>
                                        <div>{careerRelevanceFlag ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div className="col-md">
                                        <label>End Year</label>
                                        <div>{programEndYear || '---'}</div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Status</label>
                                        <div>
                                            {getSelectOption(programStatusId)?.label}
                                        </div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label>Credit Hour Requirement</label>
                                        {programCreditReqMin !== programCreditReqMax ? (
                                            <div>
                                                {programCreditReqMin}{' '}
                                                <span className="text-muted">- </span>
                                                {programCreditReqMax}
                                            </div>
                                        ) : (
                                            <div>{programCreditReqMin}</div>
                                        )}
                                    </div>
                                    <div className="col-md">
                                        <label className="d-block">Approval Date</label>
                                        <div>{makeShortDate(programApprovalDate)}</div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md mb-3 mb-md-0">
                                        <label className="d-block">Delivery Mode</label>
                                        <div>{programDistanceEd.approval}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label className="d-block">
                                            Nature of Online Program
                                        </label>
                                        <div>{programDistanceEdOnline.approval}</div>
                                    </div>
                                    <div className="col-md mb-3 mb-md-0">
                                        <label className="d-block">STEM PFF</label>
                                        <div>{programPffStemFlag ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div className="col-md">
                                        <label className="d-block">Next Level Jobs</label>
                                        <div>
                                            {workforceReadyGrantFlag ? 'Yes' : 'No'}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md mb-3">
                                        <label>Approved Locations</label>
                                        <div className="d-flex">
                                            <ul className="mr-3 p-0 mb-0 no-bullets med-type">
                                                {locations?.map(loc => (
                                                    <li key={loc.locationId}>
                                                        {loc.locationName} (
                                                        {loc.statusName})
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <label>Comments</label>
                                        <div>{programComments || '---'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {credentials != null && credentials.length != 0 && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Credential Id</th>
                                                    <th>Program Url</th>
                                                    <th>Program Description</th>
                                                    <th>Program Keywords</th>
                                                    <th>CTID</th>
                                                </tr>
                                            </thead>
                                            <tbody>{renderCredentialRows()}</tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!extraDetailsHasLoaded && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered table-hover align-middle">
                                            <thead>
                                                <tr>
                                                    <Header>Loading Data...</Header>
                                                    <Header>Loading Data...</Header>
                                                    <Header>Loading Data...</Header>
                                                </tr>
                                            </thead>
                                            <SubHeader />
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {extraDetailsHasLoaded && (
                        <div className="row">
                            <div className="col-12">
                                <div className="card p-3 mb-3">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-bordered table-hover align-middle">
                                            <thead>
                                                <tr>
                                                    <Header>{fafsaYear1}</Header>
                                                    <Header>{fafsaYear2}</Header>
                                                    <Header>{fafsaYear3}</Header>
                                                </tr>
                                            </thead>
                                            <SubHeader />
                                            <tr>
                                                <Cell>{year1Headcount}</Cell>
                                                <Cell>{year1FTE}</Cell>
                                                <Cell>{year1Degrees}</Cell>
                                                <Cell>{year2Headcount}</Cell>
                                                <Cell>{year2FTE}</Cell>
                                                <Cell>{year2Degrees}</Cell>
                                                <Cell>{year3Headcount}</Cell>
                                                <Cell>{year3FTE}</Cell>
                                                <Cell>{year3Degrees}</Cell>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-12">
                            <div className="card p-3 mb-3">
                                <div className="d-md-flex align-items-center justify-content-between text-md-left text-center mb-3">
                                    <h3 className="text-primary mb-3 mb-md-0">
                                        Program Audit
                                    </h3>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Date Modified</th>
                                                <th>User</th>
                                                <th>Fields Changed</th>
                                                <th>View</th>
                                            </tr>
                                        </thead>
                                        <tbody>{renderAuditRows()}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AcademicAffairViewAuditModal
                isOpen={isAuditModalOpen}
                toggle={toggleAudit}
                auditsList={audits}
                auditId={auditId}
                turnKey={TurnKeyToPlainText}
            />
        </React.Fragment>
    );
};
