import { authHeader } from 'helpers/auth-header';
import { api_base_url, axiosInstance } from '../common.service';
import { handleError } from 'shared';
import { handleServiceRequest } from '../handle-service-request';

const getOptions = () => {
    const getObject = {
        url: '/api/AcademicPrograms/ProgramReviewReport/Options',
    };
    return handleServiceRequest.get(getObject);
};
const getSystems = search => {
    var getObject = {url:`/api/AcademicPrograms/systems`};
    if(search !== undefined) {
        getObject = {
            url: `/api/AcademicPrograms/systems?${search}`,
        };
    }
    return handleServiceRequest.get(getObject);
};
const getAttributes = () => {
    const getObject = {
        url: '/api/AcademicPrograms/ProgramReviewReport/Attributes',
    };
    return handleServiceRequest.get(getObject);
};
const getInstitutionTypes = () => {
    const getObject = {
        url: '/api/AcademicPrograms/institutionTypes',
    };
    return handleServiceRequest.get(getObject);
};
const getCips = () => {
    const two = handleServiceRequest.get({
        url: '/api/AcademicPrograms/TwoDigitCIP',
    });
    const four = handleServiceRequest.get({
        url: '/api/AcademicPrograms/FourDigitCIP',
    });
    const six = handleServiceRequest.get({
        url: '/api/AcademicPrograms/SixDigitCIP',
    });
    return Promise.all([two, four, six]);
};
const getMetaMajor = () => {
    const getObject = {
        url: `/api/AcademicPrograms/MetaMajor`,
    };
    return handleServiceRequest.get(getObject);
};
const getInstitutions = search => {
    const getObject = {
        url: `/api/AcademicPrograms/institutions?${search}`,
    };
    return handleServiceRequest.get(getObject);
};
const getCampuses = search => {
    const getObject = {
        url: `/api/AcademicPrograms/campuses?${search}`,
    };
    return handleServiceRequest.get(getObject);
};
const getLocations = search => {
    const getObject = {
        url: `/api/AcademicPrograms/locations?${search}`,
    };
    return handleServiceRequest.get(getObject);
};
const getReport = search => {
    const getObject = {
        url: `/api/AcademicPrograms/ProgramReviewReport${search}`,
    };
    return handleServiceRequest.get(getObject);
};
const getReportExport = search => {
    const url = `${api_base_url}/api/AcademicPrograms/ProgramReviewReportExport${search}`;
    
    return axiosInstance
        .get(url, {
            headers: authHeader(),
            responseType: 'blob',
        })
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Report_Export.xls`;
            link.click();
        })
        .catch(error => {
            handleError(error);
        });
};
const getProgramsForExport = search => {
    const url = `${api_base_url}/api/AcademicPrograms/ExportProgramsFromInstitution`;
    const config = {
        headers: authHeader(),
        responseType: 'blob',
 };

    return axiosInstance
        .post(url, search, config)
        .then(res => {
            const type = res.headers['content-type'];
            const blob = new Blob([res.data], { type: type, encoding: 'UTF-8' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Program_Export.xlsx`;
            link.click();
        })
        .catch(error => {
            handleError(error);
        });
};
const getSearches = () => {
    const getObject = {
        url: `/api/AcademicPrograms/searches`,
    };
    return handleServiceRequest.get(getObject);
};
const saveSearch = postBody => {
    const postObject = {
        url: `/api/AcademicPrograms/searches`,
        postBody: postBody,
    };
    return handleServiceRequest.post(postObject);
};
const updateSearch = (id, postBody) => {
    const patchObject = {
        url: `/api/AcademicPrograms/searches/${id}`,
        patchBody: postBody,
    };
    return handleServiceRequest.patch(patchObject);
};
const deleteSearch = id => {
    const patchObject = {
        url: `/api/AcademicPrograms/searches/${id}`,
    };
    return handleServiceRequest.deleteItem(patchObject);
};

export const generateReportService = {
    getOptions,
    getProgramsForExport,
    getCips,
    getMetaMajor,
    getSystems,
    getAttributes,
    getInstitutionTypes,
    getInstitutions,
    getCampuses,
    getLocations,
    getReport,
    getReportExport,
    getSearches,
    saveSearch,
    updateSearch,
    deleteSearch,
};
