import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export const Contact = () => {
    return (
        <a href="mailto:chedss-support@che.in.gov" className="d-flex align-items-center">
            <h5 className="m-0 text-center">
                <FontAwesomeIcon size="2x" className="text-muted" icon={faEnvelope} />
            </h5>
            <div className="ml-2">
                <h5 className="text-strong mb-1">chedss-support@che.in.gov</h5>
            </div>
        </a>
    );
};

export default Contact;
