export const getOptionLabel = d => d.value;
export const convertToValue = d => ({ ...d, value: d.name });
export const convertCipToValue = d => ({ ...d, value: d.cipCode });
export const convertMetaMajorToValue = d => ({ ...d, value: d.cipName });
export const convertCampusToValue = d => ({
    ...d,
    id: d.Key || d.key,
    value: d.Value || d.value,
});
export const getDefaultValue = (options, key, isArray) => {
    const vals = [];
    options.forEach(o => {
        if (o.isDefault) vals.push(o[key]);
    });
    return isArray ? vals : vals[0];
};
export const getDefaultValues = options => {
    const defaultValues = {};
    DEFAULT_FIELDS.forEach(obj => {
        defaultValues[obj.field] = options[obj.field]
            ? getDefaultValue(options[obj.field], obj.key, obj.array)
            : obj.default;
    });
    return { ...INITIAL_FORM, ...defaultValues };
};
export const getYears = start => {
    let options = [];
    let date = new Date();
    const year = date.getFullYear();
    for (let i = year; i >= start; i--) {
        options.push({ key: String(i), value: String(i) });
    }
    return options;
};
export const getYearsAndExcludeLast = start => {
    let options = [];
    let date = new Date();
    const year = date.getFullYear();
    for (let i = year; i >= start; i--) {
        options.push({ label: String(i), value: String(i) });
    }
    return options;
};
export const PROGRAM_STATUS = [
    { value: 'New', key: 0 },
    { value: 'Active', key: 1 },
    { value: 'Suspended', key: 3 },
    { value: 'Eliminated', key: 4 },
    { value: 'Merged', key: 5 },
];
export const DEFAULT_FIELDS = [
    { field: 'institutionLevel', key: 'key', array: false, default: [] },
    { field: 'dataOutputs', key: 'key', array: true, default: [] },
    { field: 'groupPrograms', key: null, array: false, default: true },
    { field: 'programAttributes', key: 'id', array: true, default: [] },
    { field: 'programsToInclude', key: 'key', array: true, default: [] },
];
export const DEMOGRAPHIC_FILTERS = [
    { label: 'Race', value: 'race' },
    { label: 'Gender', value: 'gender' },
    { label: 'Residency', value: 'residency' },
    { label: 'Pell Recipient', value: 'pellrecipient' },
];
export const DEMOGRAPHIC_KEYS = DEMOGRAPHIC_FILTERS.map(d => d.label.replace(/ /g, ''));
export const INITIAL_FORM = {
    institutionLevel: '',
    dataOutputs: [],
    programsToInclude: [],
    programAttributes: [],
    fiscalYears: getYearsAndExcludeLast(new Date().getFullYear() - 2),
    institutionTypes: [],
    institutions: [],
    degreeLevels: [],
    metaMajor: [],
    twoCip: [],
    fourCip: [],
    sixCip: [],
    programStatus: [],
    distanceEd: '',
    groupPrograms: true,
    includeNonData: false,
    name: '',
    systems: [],
    demographics: [],
};
export const convertToArray = (val, number = false) => {
    const converted = Array.isArray(val) ? val : [val];
    return number ? converted.map(Number) : converted;
};
export const hydrateFormArray = (val, options, key) => {
    const array = convertToArray(val, true);
    let vals = [];
    options.forEach(o => {
        if (array.includes(Number(o[key]))) {
            vals.push(o);
        }
    });
    console.log(vals);
    return vals;
};
export const getCips = (params, options) => {
    const cips = convertToArray(params.cips);
    const allCips = [...options.twoCip, ...options.fourCip, ...options.sixCip];
    const foundCips = cips.map(cip => allCips.find(c => c?.cipCode === cip));
    return {
        twoCip: foundCips.filter(c => c?.cipCode?.length === 2),
        fourCip: foundCips.filter(c => c?.cipCode?.length === 5),
        sixCip: foundCips.filter(c => c?.cipCode?.length === 7),
    };
};
export const getDemographics = params => {
    let val = [];
    DEMOGRAPHIC_KEYS.forEach(key => {
        if (params[key]) val.push(key.toLowerCase());
    });
    return val;
};
